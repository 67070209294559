<template>
    <div>
        <Divider dashed><span class="divider-text">商圈站点汇总</span></Divider>
        <Button class="right" size="small" type="success" :disabled="isDownload" @click="handleDownload">导出商圈信息</Button>
        <p><span class="title">查询关键字</span> {{bussinessQuery.keyword===''?'-':bussinessQuery.keyword}}</p>
        <p><span class="title">适配范围</span>{{bussinessQuery.distance}}米</p>

        <h4 class="workplatform-title m-t-10 m-b-10">站点级别分布</h4>
        <!-- 显示等级分布 -->
        <Row :gutter="8" class="p-b-5">
            <i-col v-for="(item,i) in levelCount" :key="i" span="12" class="p-b-5">
                <div :class="chooseLevel ===item.level?'order-level-active':'order-level'" @click="handleSearchLevel(item)">
                    <span>{{item.levelName}}</span>
                    <span class="right">{{item.quantity}}</span>
                </div>
            </i-col>
        </Row>

        <h4 class="workplatform-title m-t-10">资源站点分布</h4>
        <template v-for="asset in allAssets">
          <div :key="asset.id" class="p-b-10" v-if="stationCount.filter(x=>x.assetId === asset.id).length > 0">
            <span class="title">{{asset.name}}</span>
            <div v-for="station in stationCount.filter(x=>x.assetId === asset.id)" :key="station.id" class="m-b-2"
                :class="chooseStationId===station.id?'workplatform-radio-button-active':'workplatform-radio-button'"
                 @click="changeCheckedStation(station.id)">{{station.name}}</div>
          </div>
        </template>

        <h4 class="workplatform-title m-b-5">商家清单</h4>
        <Row class="table-title">
            <i-col span="12">商家名称</i-col>
            <i-col span="6">细分类型</i-col>
            <i-col span="6" class="text-center">距离位置(米)</i-col>
        </Row>

        <Row v-for="(business,index) in bussinessData" :key="index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
            <i-col span="12">{{business.businessName}}</i-col>
            <i-col span="6">{{business.bigType}}</i-col>
            <i-col span="6" class="text-center">{{business.distance}}</i-col>
        </Row>
        <div class="paging_style">
            <Page size="small" :total="bussinessTotal" :page-size="bussinessQuery.pageSize" :current="bussinessQuery.pageNumber" @on-change="handlePageChange"></Page>
        </div>
    </div>
</template>

<script>
import { getStationByAssetIds } from '@/api/product/station'
import { getBusinessPage } from '@/api/dmp/business'

import { downloadFileRequest } from '@/utils/download'

export default {
  data () {
    return {
      allAssets: [], // 原始全部线路
      allStationList: [], // 原始的全部站点
      historyStationCount: [], // 格式化后的点位集合
      searchResultStations: [], // 查询后的站点清单
      stationCount: [],
      chooseStationId: null,
      levelCount: [],
      chooseLevel: null,
      bussinessQuery: {
        distance: 200,
        pageNumber: 1,
        pageSize: 10,
        stationId: 0,
        keyword: ''
      },
      bussinessTotal: 0,
      bussinessData: [],
      isUpdatePage: true
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      // 获取全部线路的人流量
      this.$store.dispatch('getAllowAssetList', { publisherId: this.$store.getters.token.userInfo.publisherId }).then(assets => {
        this.allAssets = assets
        const query = { assetIds: JSON.stringify(assets.map(x => x.id)) }

        getStationByAssetIds(query).then(res => {
          this.allStationList = res
          if (this.$store.state.stock) {
            this.$store.commit('set_stock_station_list', res)
            this.$store.commit('set_stock_update', new Date())
          }
        })
      })
    },
    groupByLevel () {
      const alllevels = []
      let currentLevel

      this.stationCount.forEach(element => {
        currentLevel = alllevels.find(x => x.level === element.level)
        if (currentLevel) {
          currentLevel.quantity++
        } else {
          alllevels.push({
            level: element.level, levelName: element.levelName, quantity: 1
          })
        }
      })

      this.levelCount = alllevels.sort((a, b) => (a.level - b.level))
    },
    formatPageData () {
      this.bussinessQuery.distance = this.searchCondition.arounddistance
      this.bussinessQuery.keyword = this.searchCondition.aroundkeyword

      this.stationCount = []
      this.searchResultStations = this.stations
      this.allStationList.forEach(element => {
        if (this.searchResultStations.find(x => x.stationId === element.id)) {
          this.stationCount.push(element)
        }
      })

      this.historyStationCount = this.stationCount
      this.chooseLevel = undefined
      this.groupByLevel()
    },
    handleSearchLevel (param) {
      if (this.chooseLevel === param.level) {
        this.chooseLevel = undefined
        this.stationCount = this.historyStationCount
      } else {
        this.chooseLevel = param.level
        this.stationCount = this.historyStationCount.filter(x => x.level === param.level)
      }
    },
    changeCheckedStation (stationId) {
      if (this.chooseStationId === stationId) {
        this.resetPage()
      } else {
        this.chooseStationId = stationId
        this.bussinessQuery.pageNumber = 1
        this.bussinessQuery.stationId = stationId
        this.flickerMap(stationId)
        this.searchBussinessPage()
      }
    },
    searchBussinessPage () {
      getBusinessPage(this.bussinessQuery).then(res => {
        this.bussinessTotal = res.totalRow
        this.bussinessData = res.list
      })
    },
    handlePageChange (page) {
      this.bussinessQuery.pageNumber = page
      this.searchBussinessPage()
    },
    handleDownload () {
      const parms = {
        keyword: this.bussinessQuery.keyword,
        distance: this.bussinessQuery.distance,
        stationId: this.chooseStationId
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dm/v1/business/exportBusinessInfo', parms, '点位周边商圈信息.xlsx')
    },
    restoreMap () { // 还原地图点位标识
      this.isUpdatePage = false
      this.$store.commit('set_stock_map_type', 'resource')
      this.$store.commit('set_stock_stations', this.searchResultStations)
      this.$store.commit('set_stock_update', new Date())
    },
    flickerMap (stationId) { // 地图闪光标识
      this.isUpdatePage = false

      this.$store.commit('set_stock_map_type', 'flicker')
      this.$store.commit('set_stock_stations', [stationId])
      this.$store.commit('set_stock_update', new Date())
    },
    resetPage () {
      this.chooseStationId = null
      this.bussinessQuery.pageNumber = 1
      this.bussinessTotal = 0
      this.bussinessData = []
      this.restoreMap()
    }
  },
  computed: {
    isDownload () {
      return this.bussinessData.length < 1
    },
    beginUpdate () {
      return this.$store.state.stock.beginUpdate
    },
    searchCondition () {
      return this.$store.state.stock.searchCondition
    },
    stations () {
      return this.$store.state.stock.stations
    },
    searchRange () {
      return this.$store.state.stock.searchRange
    }
  },
  watch: {
    beginUpdate (val) {
      if (this.isUpdatePage) {
        this.formatPageData()
      } else {
        this.isUpdatePage = true
      }
    },
    stationCount (val) {
      this.resetPage()
    },
    searchRange (val) {
      this.bussinessQuery.pageNumber = 1
      this.bussinessQuery.distance = val

      // 选中站点
      if (this.chooseStationId !== null) {
        this.searchBussinessPage()
      }
    }
  }
}
</script>
